import Swiper from 'swiper';
import anime from 'animejs/lib/anime.es.js';

export function topMainVisualSlide() {
    var mySwiper = new Swiper('.swiper-container', {
        speed: 700,
        slidesPerView: 1,
        watchOverflow: false,
        loop: true,
        loopedSlides: 1,
        noSwiping:'swiper-no-swiping',
        autoplay: {
            delay: 5000,
            disableOnInteraction: false, 
        },
        navigation: {
            nextEl: '.page_pagination_arrow_next',
            prevEl: '.page_pagination_arrow_prev',
        },
        thumbs: {
            swiper: mySwiperthumbs,
        },
    });

    var mySwiperthumbs = new Swiper('.swiper-container-thumbs', {
        speed: 700,
        slidesPerView: 1,
        watchOverflow: true,
        loop:true,
        loopedSlides: 1,
        noSwiping:'swiper-no-swiping',
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.page_pagination_arrow_next',
            prevEl: '.page_pagination_arrow_prev',
        },
        // pagination: {
        //     el: '.page_pagination_progressbar_line',
        //     type: 'progressbar',
        //     clickable: true,
        // },
        thumbs: {
            swiper: mySwiper,
        },
    });

    mySwiper.on('slideChange', function () {
        document.querySelector('.page_pagination_progressbar_line_bar').style.width = '0%';
        lineload();
      });
    lineload();
    //slide loading
    function lineload(){
        anime({
            targets: '.page_pagination_progressbar_line_bar ',
            width: '100%',
            duration: 5000,
            easing: 'linear',
            delay: 0
          });
    }

}