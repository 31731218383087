import anime from 'animejs/lib/anime.es.js';

export default () => {
    let headerspbtn = document.getElementsByClassName('header_block_sp_btn');
    let headerspmenu = document.getElementsByClassName('header_sp_menu');

    function header_sp_btn() {
        headerspbtn[0].addEventListener('click', function () {
            headerspbtn[0].classList.toggle('active');
            if (headerspmenu[0].classList.contains('fadein_menu')) {
                headerspmenu[0].classList.remove('fadein_menu');
                headerspmenu[0].classList.add('fadeout_menu')
            } else {
                headerspmenu[0].classList.add('fadein_menu')
                headerspmenu[0].classList.remove('fadeout_menu');
            }
        });
    }
    header_sp_btn()
}